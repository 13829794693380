<!-- list -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button
          icon="iconfont icon-baocun"
          type="primary"
          @click="save()"
        >
          保存
        </el-button>
        <el-button
          icon="iconfont icon-fanhui"
          @click="$router.back()"
        >
          返回
        </el-button>
      </template>
    </page-title>
    <div class="role-inner">
      <div class="title">
        {{ $route.query.orgName }}-设置人员
      </div>
      <div class="role-header">
        已选择员工：
      </div>
      <div class="role-tag">
        <el-tag
          v-for="tag in selection"
          :key="tag.employeeCode"
          closable
          @close="handleClose(tag)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="partition-area">
      <search-inner :search-id="1" :search-form="searchForm" @submit-search="searchData" @clear-search="reset">
        <template>
          <el-col :span="6">
            <el-form-item label="用户名：">
              <el-input v-model="searchForm.accountLike" placeholder="请输入用户名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="姓名：">
              <el-input v-model="searchForm.nameLike" placeholder="请输入姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号：">
              <el-input v-model="searchForm.mobileLike" placeholder="请输入手机号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态：">
              <el-select v-model="searchForm.state" placeholder="全部" filterable>
                <el-option label="全部" value="" />
                <el-option label="启用" value="20" />
                <el-option label="禁用" value="10" />
              </el-select>
            </el-form-item>
          </el-col>
        </template>
      </search-inner>
      <table-inner v-loading="loading.list" title="筛选结果" :page-num="list.pageNum" :page-size="list.pageSize" :pages="list.pages" :page-id="list.pageId" @changePageNum="changePageNum">
        <template slot="table-bd">
          <el-table ref="selectTable" :data="list.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" :selectable="setSelection" min-width="50" />
            <el-table-column prop="employee.name" label="姓名" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column label="状态" align="center" show-overflow-tooltip min-width="150">
              <template slot-scope="scope">
                <span v-if="_.get(scope, 'row.employee.status') === '20'" class="success">已启用</span>
                <span v-else class="danger">已禁用</span>
              </template>
            </el-table-column>
            <el-table-column prop="orgNameList" label="所属组织机构" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="employee.mobile" label="手机号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
            <el-table-column prop="user.account" label="用户名" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
          </el-table>
        </template>
      </table-inner>
    </div>
  </el-main>
</template>
<script>
export default {
  components: {
  },
  data () {
    return {
      apiGroup: 'org',
      orgCode: this.$route.query.orgCode,
      searchForm: {
        accountLike: '',
        nameLike: '',
        mobileLike: '',
        state: '',
        pageNum: '1'
      },
      row: {},
      // 用于列表勾选和回显
      selectionAll: [],
      showAdd: false,
      loading: {
        list: false,
        submit: false
      },
      // 用于上方显示已选择保存的人员
      selection: [],
      list: {}
    }
  },
  watch: {
  },
  created () {
    this.findAuth()
    this.getAnd()
  },
  mounted () {
  },
  methods: {
    findAuth () {
      const systemFeature = this._.find(this.$store.state.featureList, { code: 'org-platform' })
      if (systemFeature) {
        this.apiGroup = 'org'
      } else {
        this.apiGroup = 'org'
      }
    },
    searchData () {
      this.searchForm.pageNum = 1
      this.search()
    },
    reset () {
      this.searchForm = {
        accountLike: '',
        nameLike: '',
        mobileLike: '',
        state: '',
        pageNum: '1'
      }
      this.$router.push({ query: this.searchForm })
      this.search()
    },
    changePageNum ({ pageNum, pageId }) {
      this.searchForm.pageNum = pageNum
      this.search()
    },
    search () {
      this.getList(this.searchForm)
    },
    getList (searchForm) {
      this.loading.list = true
      this.api.system.employee.userPage(searchForm).then(result => {
        this.loading.list = false
        this.list = result.data.data
        // this.setSelection()
      }).catch(e => {
        this.loading.list = false
      })
    },
    handleCurrentChange (val) {
      this.searchForm.pageNum = val
      this.getList(this.searchForm)
    },
    // 跳页回显已选择的
    setSelection (row) {
      return !this._.find(this.selection, { employeeCode: row.employee.employeeCode })
    },
    // 获取组织机构员工关联
    getAnd () {
      this.api.system.org.listEmployee(this.orgCode).then(result => {
        this.selection = result.data.data
        this.search()
      }).catch(e => {
      })
    },
    // 选择列表
    handleSelectionChange (rows) {
      // let data = []
      // // 深复制
      // for (let i in this.selectionAll) {
      //   data.push(this.selectionAll[i])
      // }
      // let selects = ''
      // for (let i in data) {
      //   if (row.employee.employeeCode === data[i].employee.employeeCode) {
      //     selects = i
      //     break
      //   }
      // }
      // if (selects) {
      //   data.splice(selects, 1)
      // } else {
      //   data.push(row)
      // }
      this.selectionAll = rows
      // console.log(this.selectionAll)
    },
    // 保存
    save () {
      console.log(this.selectionAll)
      const employeeArr = []
      if (this.selectionAll && this.selectionAll.length > 0) {
        this.selectionAll.forEach(item => {
          if (item.employee) {
            employeeArr.push(item.employee.employeeCode)
          }
        })
      } else {
        this.$message.error('请选择至少一名人员')
        return false
      }
      this.loading.submit = true
      console.log(this.apiGroup)
      this.api.system.org.addEmployee(this.orgCode, employeeArr).then(result => {
        this.$message.success(result.data.message || '添加成功')
        this.selectionAll = []
        this.getAnd()
        this.loading.submit = false
      }).catch(e => {
        this.loading.submit = false
      })
    },
    // tag 删除
    handleClose (tag) {
      this.selection.splice(this.selection.indexOf(tag), 1)
      let employeeCode = ''
      employeeCode = tag.employeeCode
      this.api.system.org.removeEmployee(this.orgCode, employeeCode).then(result => {
        this.$message.success(result.data.message || '删除成功')
        this.getAnd()
      }).catch(e => {
      })
    },
    toggleSelection (rows) {
      this.$refs.selectTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.selectTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.selectTable.clearSelection()
      }
    }
  }
}
</script>
<style lang="less">
</style>
